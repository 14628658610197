.back {
    background-color: #f5f8ff;
    width: 100%;
    height: 100vh;
}

.userInfo {
    display: flex;
    align-items: center;
    margin-top: 32px;
}

.role {
  width: 33px;
  height: 16px;
  border-radius: 4px;
  background: var(--BC1, #00a6ff);
  display: inline-flex;

  margin-left: 28px;
  justify-content: center;
  align-items: center;
  border: 1px solid #00a6ff;

  color: var(--BG1, #fff);

  font-size: 12px;
  font-weight: 600;
}

.name {
  color: var(--Font1, #333);
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 600;
  margin: 0 8px;
}

.email {
  color: var(--Font3, #888);

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
}

.topWord {
  color: #333;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin: 2%;
  margin-top: 4%;
  margin-left: 5%;
}

.list {
    width: 70vw;
    height: 75vh;
    margin-top: 4%;
}

.box {
  width: 492px;
  height: 84px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 2px solid var(--, #00a6ff);
  background: #fff;
  color: #333;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  justify-items: left;
  align-items:end;
  display: flex;
  margin-left: 40px;
  margin-top: 15px;
}

.mobilebox {
  width: 90vw;
  height: 80px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 2px solid var(--, #00a6ff);
  background: #fff;
  color: #333;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  justify-items: left;
  align-items: center;
  display: flex;
  margin-left: 5%;
  margin-top: 2%;
}
