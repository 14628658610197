.totalBox {
  width: 500px;
  margin: auto auto;
}
.goBack {
  background-color: white;
  border: none;
  font-size: 24px;
  color: #888888;
  cursor: pointer;
  margin-left: 20px;
  padding: 0;
}
.title {
  display: inline;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 24px;
}

.title:hover {
  cursor: pointer;
}
.selectBox {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  border: 2px solid #5c80ff;
  border-radius: 16px;
  margin-right: 24px;
}

.selectBoxTitle {
  margin-left: 20px;

  font-family: 'Pretendard';
  color: #333333;
  font-weight: 600;
  font-size: 32px;
}
.selectBoxText {
  margin-left: 20px;
  margin-top: 70px;
}
.selectText {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #555555;
  text-decoration-line: none;
}

@media screen and (max-width: 768px) {
  .selectBox {
    display: inline-block;
    position: relative;
    width: 30vw;
    height: 184px;
    border: 2px solid #5c80ff;
    border-radius: 16px;
    margin-top: 12px;
    margin-right: 10px;
    width: 150px;
    height: 144px;
  }
  .totalBox {
    width: 100vw;
  }

  .selectText p {
    margin: 0;
  }
  .selectBoxText {
    position: absolute;
    margin: 0;
    right: 16px;
    bottom: 14px;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    color: var(--Font2, #555);
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 600;
  }
  .selectBoxTitle {
    margin-left: 24px;
    margin-top: 20px;
    font-family: 'Pretendard';
    color: var(--BC3, #3460fe);
    font-size: 24px;
    font-weight: 700;
  }
  .title {
    color: var(--Font1, #333);
    display: flex;
    width: 80px;
    font-family: Jalnan;
    font-size: 20px;
    font-weight: 700;
    align-items: center;
    margin-left: 4px;
  }
  .mobileTitle {
    color: var(--Font1, #333);
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px; /* 140% */
  }
  .mobileSelectBox {
    width: 90vw;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }
}
