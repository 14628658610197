@font-face {
  font-family: "yg-jalnan";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.post {
  height: 25vh;
  background: #f5f8ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  color: var(--Font1, #333);
  font-family: yg-jalnan;
  font-weight: 700;
  line-height: 1px;
}

.post h1 {
  color: var(--BC3, #3460fe);
  text-align: center;
  font-family: yg-jalnan;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 3%;
}

.background {
  box-sizing: border-box;
  background-color: #ffffff;
  border: solid 1px #ffcc47;
  border-radius: 26px;
  width: 400px;
  margin: 0 auto;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    border: none;
    width: 350px;
    margin-bottom: 5px;
  }
}
.setForm {
  margin: 0px 20px;
}
.titleBox {
  display: flex;
  line-height: 50px;
}
.title {
  color: #333333;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-left: 15px;

  @media screen and (max-width: 768px) {
    margin-left: 1%;
    line-height: 5%;
    font-size: 22px;
    margin-bottom: 0px;
  }
}
.regist {
  text-decoration: none;
  color: #ff4636;
  display: flex;
  font-size: 16px;
}
.registBox {
  display: flex;
  margin-top: 38px;
  margin-left: 120px;
}
.inputForm {
  width: 320px;
  margin: 0 auto;
}
.inputs {
  width: 320px;
  height: 44px;
  margin: 0 auto;
  border-radius: 10px;
  border: solid 1px #cccccc;
  box-shadow: 0px 10px 40px rgba(174, 174, 174, 0.2);
  text-indent: 17px;
  font-size: 16px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    width: 305px;
  }
}
.inputName {
  margin: 15px 0 5px 0;
  color: #333333;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  > span {
    color: var(--main-color);
  }
}
.addressText {
  margin-top: 10px;
  cursor: pointer;
  color: #888888;
}
.addressText:before {
  float: right;
  margin-right: 10px;
  font-family: "FontAwesome";
  content: "\f002";
}

.inputsSmall {
  width: 154px;
  height: 44px;
  border-radius: 10px;
  border: solid 1px #cccccc;
  box-shadow: 0px 10px 40px rgba(174, 174, 174, 0.2);
  text-indent: 20px;
  font-size: 14px;
  display: grid;
  float: left;
}
.gradeText {
  margin-left: 8%;
  margin-bottom: 5px;
  color: #333333;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  > span {
    color: var(--main-color);
  }
}
.inputsSmallGrade {
  display: grid;
  width: 320px;
  height: 49px;
  border-radius: 20px;
  box-shadow: 0px 10px 40px rgba(174, 174, 174, 0.2);
  text-indent: 8px;
  font-size: 14px;
  left: 25px;

  @media screen and (max-width: 768px) {
    width: 310px;
  }
}
.terms_list {
  margin: 10px 20px;
  padding-left: 0;
}
.terms_box {
  overflow: auto;
  margin-top: 17px;
  box-sizing: border-box;
  max-height: 100px;
  border-radius: 6px;
  border: 1px solid #d6d6d6;
  color: #555555;
}
.terms_box_all {
  margin-top: 17px;
  color: #555555;
}
.terms_set {
  margin-top: 30px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  clear: left;
}
.check {
  list-style: none;
}
.checktext {
  font-size: 20px;
  font-weight: 500;
  display: inline;
  vertical-align: top;
}
.color {
  color: #00a6ff;
}
.color2 {
  color: #888;
}
.btn {
  position: relative;
  width: 320px;
  height: 48px;
  background: var(--BC3, #3460fe);
  color: white;
  cursor: pointer;
  border: 0;

  border-radius: 10px;
  font-size: 20px;
  box-shadow: 0px 10px 40px rgba(174, 174, 174, 0.2);
  margin: 20px;

  @media screen and (max-width: 768px) {
    width: 280px;
    margin: 15px;
  }
}
.btn:disabled {
  cursor: auto;
  background-color: rgba(146, 148, 149, 0.3);
}
.btn:enabled:hover {
  box-shadow: 3px 3px 3px rgb(172, 172, 172), 3px 3px 3px rgb(237, 237, 237);
  transition: 0.3s;
}
.backSignIn {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 20px auto;
}

.backText {
  color: #333333;
}

.select {
  display: flex;
  gap: 5%;
  margin-left: -7%;

  @media screen and (max-width: 768px) {
    margin-left: -8%;
  }
}

.mobileNav {
  display: none;
  @media screen and (max-width: 768px) {
    width: 94%;
    height: 2.5em;
    color: #333;
    font-family: RixInooAriDuri;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    justify-content: left;
    align-items: center;
    display: flex;
    margin-left: 3%;
    gap: 1%;
  }
}

.arrow_button {
  font-size: 24px;
  color: #888888;
  cursor: pointer;
}

.linkWithoutUnderline {
  text-decoration: none; /* 밑줄 제거 */
}
