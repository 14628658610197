.wrap {
  min-height: 28vh;
  position: relative;
  width: 100vw;
  background: white;
}
section {
  padding-bottom: 259px;
}

footer {
  width: 100%;
  height: 208px;
  bottom: 0px;
  position: absolute;
  border-top: 1px solid #f4f4f4;
  padding-top: 15px;
  color: #808080;
  font-size: 11px;
  background: #f4f4f4;

  @media screen and (max-width: 768px) {
  }
}

footer nav h1 {
  color: #333;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

footer nav h2 {
  color: #00a6ff;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

footer p {
  color: #555;
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media screen and (max-width: 768px) {
    text-align: left;
    margin-left: 3%;
  }
}

footer p span {
  display: inline-block;
  margin: 5px;
}

.custom_span {
  color: #cccccc;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.title {
  display: inline-block;
  margin: 5px;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

footer .h_blue span {
  display: inline-block;
  margin: 5px;
}

.footer_left {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 10%;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: left;
    margin-left: 4%;
    bottom: 15%;
  }
}

.navbar {
  box-sizing: border-box;
  width: 100%;
  height: 456px;
  flex-shrink: 0;
  margin-bottom: 119px;
  background: #ffcc47;
}

.top {
  box-sizing: border-box;
  width: 100%;
  height: 480px;
  flex-shrink: 0;
  background: #ffcc47;
  margin-bottom: 98px;
}

.mid {
  box-sizing: border-box;
  width: 100%;
  height: 1690px;
  flex-shrink: 0;
  background: #d9d9d9;
  margin-bottom: 98px;
}

.h_black {
  color: #555;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  @media screen and (max-width: 768px) {
    color: #888;
  }
}

.h_blue {
  color: #00a6ff;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.line {
  display: none;

  @media screen and (max-width: 768px) {
    display: inline;
  }
}
