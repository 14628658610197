@font-face {
  font-family: 'yg-jalnan';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.wrap {
  min-height: 100vh;
  position: relative;
  width: 100%;
  background: white;

  @media screen and (max-width: 768px) {
    display: none;
  }
}
.linkStyle_l {
  text-decoration: none;
  color: #555555;
  width: 200px;
  display: flex;
  align-items: center;
}
.linkStyle_r{
  text-decoration: none;
  color: #555555;
  width: 200px;
}
.header {
  height: 75px;
  padding: 1rem;
  color: #555555;
  background: white;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.LECO {
  display: inline-block;
}

.header h1 {
  color: var(--Font1, #333);
  font-family: 'yg-jalnan';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}

.letter1 {
  color: #00a6ff;
  font-family: "LotteriaChab";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 150% */
}

.letter2 {
  color: #ffd000;
  font-family: "LotteriaChab";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 150% */
}

header span {
  display: inline-block;
  margin: 15px;
}

.navbar {
  box-sizing: border-box;
  width: 100%;
  height: 456px;
  flex-shrink: 0;
  margin-bottom: 119px;
}
