.preview {
    width: 600px;
    height: 892px;
    float: right;
    background: #FFFFFF;
    border-radius: 24px;
    line-height: 24px;
    position: absolute;
    top: 100px;
    left: 780px;
}

.Video {
    width: 200px;
    height: 192px;
}