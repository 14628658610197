.title {
  color: var(--Font1, #333);
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 700;

  margin-bottom: 12px;
  width: 310px;
  margin: 0 auto;
  margin-top: 34px;
}
.authBox {
  width: 310px;
  margin: 0 auto;
}
.inputs {
  width: 291px;
  height: 48px;
  display: flex;
  margin: 0 auto;
  border-radius: 8px;
  border: 1px solid var(--Line1, #ccc);
  margin-top: 2px;

  padding-left: 16px;

  color: var(--Font3, var(--navbar, #888));
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.bottomBtn {
  position: fixed;
  margin: 0 auto;
  bottom: 16px;
  width: 310px;
  height: 48px;

  border: 0px;
  border-radius: 8px;
  background: var(--BC3, #3460fe);

  color: var(--BG1, #fff);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}
.bottomBtn:hover {
  cursor: pointer;
}

.bottomBtn:disabled {
  cursor: auto;
  background-color: rgba(146, 148, 149, 0.3);
}
.authBtn {
  margin-top: 6px;
  width: 310px;
  height: 48px;

  border: 0px;
  border-radius: 8px;
  background: var(--BC3, #3460fe);

  color: var(--BG1, #fff);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}
.authBtn:hover {
  cursor: pointer;
}

.authBtn:disabled {
  cursor: auto;
  background-color: rgba(146, 148, 149, 0.3);
}

.subTitle {
  color: var(--Font1, #333);

  font-size: 14px;
  font-weight: 600;

  margin-top: 12px;
}

.sexBtn {
  width: 76px;
  height: 40px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid var(--Line1, #ccc);
  margin-top: 2px;
}
.sexBtnClick {
  width: 76px;
  height: 40px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid var(--Line1, #ccc);
  margin-top: 2px;
  background-color: #00a6ff;
  color: #fff;
}
.sexBtn:hover {
  cursor: pointer;
  background-color: #00a6ff;
  color: #fff;
}
