@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

* {
  font-family: Pretendard, "Noto Sans KR", sans-serif;
}

@font-face {
  font-family: "RixInooAriDuriR";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2207-01@1.0/RixInooAriDuriR.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

:root {
  --main-color: #3460fe;
  --sub-color: #ff6500;
}
