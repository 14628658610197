@font-face {
  font-family: 'RixInooAriDuri';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2207-01@1.0/RixInooAriDuriR.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}
.authSendBtn {
  width: 320px;
  height: 44px;
  margin-top: 5px;
  border-radius: 8px;
  background: var(--BC3, #3460fe);
  border: 0px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}
button:hover {
  cursor: pointer;
}
.container {
  width: 100%;
  height: 100%;
  padding-top: 52px;
  padding-bottom: 52px;
  background: #f5f8ff;
  @media screen and (max-width: 768px) {
  }
}

.post {
  width: 100%;
  height: 15em;
  background: var(--BC3, #3460fe);
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: RixInooAriDuri;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1px; /* 142.857% */
  color: var(--BG1, #fff);
}

.post > h1 {
  font-family: RixInooAriDuri;
}
.errorSign {
  color: #f00;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 4px;
  display: flex;
}
.smallErrorSign {
  color: red;
  margin: 2px 5px;
}
.background {
  box-sizing: border-box;
  background-color: #ffffff;
  padding-bottom: 40px;
  border-radius: 26px;
  width: 400px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    border: none;
    width: 100%;
  }
}
.setForm {
  margin: 0px 20px;
}
.titleBox {
  display: flex;
  line-height: 50px;
  margin: 10px auto;
  margin-bottom: 0;
}
.title {
  display: inline;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 24px;
}
.title {
  color: #333333;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-left: 15px;
  margin-top: 28px;
}
.regist {
  text-decoration: none;
  color: #ff4636;
  display: flex;
  font-size: 16px;
}
.registBox {
  display: flex;
  margin-top: 38px;
  margin-left: 120px;
}
.inputForm {
  width: 320px;
  margin: 0 auto;
}
.inputs {
  width: 320px;
  height: 44px;
  margin: 0 auto;
  border-radius: 10px;
  border: solid 1px #cccccc;
  box-shadow: 0px 10px 40px rgba(174, 174, 174, 0.2);
  text-indent: 17px;
  font-size: 16px;
  font-weight: 400;
}
.inputName {
  margin-top: 15px;
  margin-bottom: 6px;
  color: #333333;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.addressText {
  margin-top: 15px;
  cursor: pointer;
  color: var(--Font3, var(--navbar, #888));
}
.addressText:before {
  float: right;
  margin-right: 10px;
  font-family: 'FontAwesome';
  content: '\f002';
}

.inputsSmall {
  width: 154px;
  height: 44px;
  border-radius: 10px;
  border: solid 1px #cccccc;
  box-shadow: 0px 10px 40px rgba(174, 174, 174, 0.2);
  text-indent: 20px;
  font-size: 14px;
  display: grid;
  float: left;
}
.gradeText {
  margin-left: 130 px;
  color: #333333;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
}
.inputsSmallGrade {
  display: grid;
  width: 155px;
  height: 44px;
  border-radius: 10px;
  border: solid 1px #cccccc;
  box-shadow: 0px 10px 40px rgba(174, 174, 174, 0.2);
  text-indent: 8px;
  font-size: 14px;
  left: 20px;
}
.terms_list {
  color: var(--, #555);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.terms_ul {
  padding-left: 0;
  width: 320px;
}
.terms_box {
  width: 320px;
  overflow: auto;
  margin-top: 8px;
  box-sizing: border-box;
  max-height: 100px;
  border-radius: 6px;
  border: 1px solid #d6d6d6;
  color: #555555;
}
.terms_box_all {
  margin-top: 17px;
  color: #555555;
}
.terms_set {
  margin-top: 12px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  clear: left;

  color: var(--, #555);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.check {
  list-style: none;
}
.checktext {
  font-size: 20px;
  font-weight: 500;
  display: inline;
  vertical-align: top;

  color: var(--Font1, #333);

  font-family: Pretendard;
  font-size: 16px;
  line-height: 24px; /* 150% */
}
.checkbox {
  margin-bottom: 0;
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  background-color: gainsboro;
  width: 20px;
  height: 20px;
  border: 1.5px solid gainsboro;
  border-radius: 15px;
  cursor: pointer;
}
.checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-color: #ffcc47;
}
.color {
  color: var(--BC3, #3460fe);
}
.color2 {
  color: #888;
}
.btn {
  width: 320px;
  height: 48px;

  background: var(--BC3, #3460fe);

  color: white;
  cursor: pointer;
  border: 0;
  border-radius: 10px;
  font-size: 20px;
  box-shadow: 0px 10px 40px rgba(174, 174, 174, 0.2);
  margin-top: 8%;
  @media screen and(max-width: 768px) {
    width: 60%;
  }
}
.btn:disabled {
  cursor: auto;
  background-color: rgba(146, 148, 149, 0.3);
}
.btn:enabled:hover {
  box-shadow: 3px 3px 3px rgb(172, 172, 172), 3px 3px 3px rgb(237, 237, 237);
  transition: 0.3s;
}
.backSignIn {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 20px auto;
}

.backText {
  padding-bottom: 40px;
  color: #333333;
}
.duplicateBtn {
  width: 72px;
  height: 44px;
  margin-left: 6px;
  border-radius: 8px;
  background: var(--BC3, #3460fe);
  border: 0px;

  color: var(--BG1, #fff);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.goBack {
  background-color: white;
  border: none;
  font-size: 24px;
  color: #888888;
  cursor: pointer;
  margin-left: 20px;
  padding: 0;
}

.profileText {
  margin-top: 10px;
  width: 120px;
  height: 44px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid var(--SC3, #5c80ff);
  font-weight: 800;
  font-size: 14px;
  color: var(--BC3, #3460fe);

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgb(77, 77, 77);
    color: #fff;
  }
}
.profileImg {
  margin-top: 10px;
  width: 120px;
  height: 120px;
  border-radius: 120px;
}
@media screen and (max-width: 768px) {
  .profileText {
    margin: 10px auto;
  }
  .profileImg {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: 160px;
    height: 160px;
    border-radius: 120px;
  }
  .btn {
    display: flex;
    margin: 0 auto;
    width: 310px;
    justify-content: center;
    align-items: center;
  }
  .title {
    color: var(--Font1, #333);
    font-family: 'jalnan';
    font-size: 20px;
    font-weight: 400;
    margin-left: 4px;
  }

  .terms_set {
    width: 310px;
  }
  .check {
    width: 310px;
    margin: 0 auto;
  }
  .terms_list {
    width: 310px;
    margin: 0 auto;
  }
  .btn {
    margin-top: 20px;
  }
  .terms_box {
    width: 310px;
  }
  .inputs {
    width: 308px;
  }
}

@media screen and (min-width: 768px) {
  .duplicateText {
    margin-left: -245px;
    font-size: 14px;
  }
}
