.background {
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 26px;
  width: 400px;
  height: 456px;
  margin: 0 auto;
}
.loginBtn {
  display: flex;
  margin: 20px auto;
  width: 320px;
  height: 48px;
  background-color: var(--BG1, #fff);
  color: var(--BC3, #3460fe);
  cursor: pointer;
  border: 1px solid var(--BC3, #3460fe);
  border-radius: 8px;
  font-size: 20px;
  font-weight: 700;
}
.loginBtn:disabled {
  cursor: auto;
  background-color: rgba(146, 148, 149, 0.3);
}
.loginBtn:enabled:hover {
  box-shadow: 3px 3px 3px rgb(172, 172, 172), 3px 3px 3px rgb(237, 237, 237);
  transition: 0.3s;
}
.loginText {
  display: flex;
  margin: auto;
  font-size: 16px;
}
.inputBox {
  width: 400px;
}
.inputs {
  display: flex;
  margin: 8px auto;
  width: 320px;
  height: 40px;
  border-radius: 10px;
  border: solid 1px #cccccc;

  text-indent: 20px;
  font-size: 14px;
}
.inputs::placeholder {
  padding-left: 5px;
  font-size: 14px;
}
input:focus {
  outline: none;
}
.titleBox {
  display: flex;
  align-items: center;
  width: 320px;
  padding-top: 28px;
  margin: 0 auto;
}

.title {
  font-family: jalnan;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin-left: 6px;
}

.checkbox {
  width: 320px;
  margin: 13px 42px 0px 42px;
}
.find {
  position: flex;
  float: right;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: #555555;
}
.maintain {
  position: absolute;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #555555;
}
.signUpBox {
  display: flex;
  margin: 0 auto;
}
.signUp {
  width: 400px;
  display: flex;
  font-size: 16px;
}
.signUpText {
  font-weight: 100;
  color: #333333;
  text-decoration-line: underline;
  font-weight: 600;
}

.btnBox {
  width: 400px;
  margin-top: 20px;
}
.Button {
  background: white;
  color: #444;
  width: 320px;
  height: 44px;
  border-radius: 10px;
  display: flex;
  margin: 10px auto;
  border: 1px #e6e6ea;

  font-weight: 500;
  font-size: 16px;
}

.Button:hover {
  cursor: pointer;
}

.buttonText {
  display: flex;
  margin: auto auto;
}

.btnLogo {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}
.kakao {
  color: #3b1e1e;
  font-weight: 600;
  font-family: 'Pretendard';
  background-color: #fee500;
}
.naver {
  color: #ffffff;
  font-weight: 500;
  font-family: 'Pretendard';
  background-color: #00bf18;
}
.loginBox {
  margin-top: 52px;
}
@media screen and (max-width: 768px) {
  .title {
    color: var(--Font1, #333);
    font-family: Jalnan;
    font-size: 20px;
    font-weight: 700;
    margin-top: 6px;
  }

  .background {
    border: 0px;
  }
  .loginBox {
    margin-top: 0px;
  }
}
